import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ServerSideState } from '@price-for-profit/data-grid';
import { useSelectedTemporalCustomerPrice } from 'shared/providers/provideSelectedTemporalCustomerPrice';
import {
    useCompanyAddressesDropdown,
    useCompanyNamesDropdown,
    useTemporalCustomerPricesQuoteQuery,
} from 'shared/queries';
import { IViewTemporalCustomerPricesPage } from 'shared/types';
import { TemporalCustomerPricesQuoteForm } from './TemporalCustomerPricesQuoteForm';
type TemporalCustomerPricesQuoteModalProps = {
    state: ServerSideState;
    rows: IViewTemporalCustomerPricesPage[];
};

export function TemporalCustomerPricesQuoteModal({ state }: TemporalCustomerPricesQuoteModalProps) {
    const { close, isQuoteModalOpen } = useSelectedTemporalCustomerPrice();
    const { data: allRows, isLoading } = useTemporalCustomerPricesQuoteQuery({
        state,
        enabled: isQuoteModalOpen,
    });

    const { data: companyNames, isFetching: isFetchingCompanyNames } = useCompanyNamesDropdown();

    const { data: companyAddresses, isFetching: isFetchingCompanyAddresses } = useCompanyAddressesDropdown();

    return (
        <Dialog onClose={close} open={isQuoteModalOpen} maxWidth={'lg'} fullWidth>
            <DialogTitle>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <span>Quote:</span>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TemporalCustomerPricesQuoteForm
                    key={JSON.stringify(state)}
                    isFetchingCompanyAddresses={isFetchingCompanyAddresses}
                    companyAddresses={companyAddresses}
                    isFetchingCompanyNames={isFetchingCompanyNames}
                    companyNames={companyNames}
                    allRows={allRows}
                    isLoading={isLoading}
                />
            </DialogContent>
            <DialogActions>
                <Box display='flex' justifyContent='end' gap={2} pt={4}>
                    <Button onClick={close}>Close</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
