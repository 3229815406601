import { useService, useUser } from '@insight2profit/drive-app';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
} from '@mui/material';
import {
    DataGridPremium,
    GridColDef,
    GridFilterModel,
    GridLinkOperator,
    GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';
import { useI2pDataGridEdit, useI2pServerDataGrid } from '@price-for-profit/data-grid';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProductPricesCustomToolbar } from 'shared/components/app/productPrices/ProductPricesCustomToolbar';
import { UOM_KAI } from 'shared/constants';
import { DATA_GRID_STYLE, HEADER_HEIGHT } from 'shared/constants/dataGrid';
import { QUERY_KEYS } from 'shared/constants/queryKeys';
import { makeMaterialUomList, useUserPermissions } from 'shared/hooks';
import { useTrackPageView } from 'shared/hooks/use-track-page-view';
import {
    ProvidePricesInvalidator,
    useCustomerProductEngineBatchMetaDatas,
    useExchangeRates,
    useProductPricesLocks,
} from 'shared/providers';
import { useUomDropdown } from 'shared/queries';
import { MaterialNonStandardUOMConversion } from 'shared/types';
import { IViewProductPrices } from 'shared/types/productPrices';
import { determineIsEdited, round } from 'shared/utility';
import { getNonStandardUomDropdownParams } from 'shared/utility/dropdown';
import { useInvalidateAfterBatchUpdate } from '../../shared/hooks/productPrices/useInvalidateAfterBatchUpdate';
import { ProductPriceDrawer } from './productPriceDrawer/ProductPriceDrawer';

interface ProductPricesProps {
    columns: GridColDef<IViewProductPrices>[];
    selectedProduct: IViewProductPrices | null;
    setSelectedProduct: React.Dispatch<React.SetStateAction<IViewProductPrices | null>>;
}

export const globalFields = [
    'globalRevisedMinimum',
    'globalRevisedTarget',
    'globalRevisedMinimumCMPercent',
    'globalRevisedTargetCMPercent',
];

export function ProductPrices({ columns, selectedProduct, setSelectedProduct }: ProductPricesProps) {
    const {
        productPricesService,
        productPricesPersistedAttributesService,
        uomConversionService,
        nonStandardUomDropdownService,
    } = useService();
    const { enqueueSnackbar } = useSnackbar();

    const user = useUser();
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const { data: permissions, permittedRowLevels, userApproverTier, preferences } = useUserPermissions();
    const { isForeignCurrency } = useExchangeRates();
    const [searchParameters] = useSearchParams();
    const { data: uomRows } = useUomDropdown();
    const { isBusinessLineLocked } = useProductPricesLocks();
    const [regionalCurrencyLocked, setRegionalCurrencyLocked] = useState(false);

    const idString = searchParameters.get('ids');
    const idArray = idString ? idString.split('|') : [];

    useTrackPageView('productPrices', user?.displayName || '', user?.email || '', user?.id || '');

    const filterModelItems = idArray.map(anId => {
        return {
            id: anId,
            columnField: 'newRecordId',
            operatorValue: 'contains',
            value: anId,
        };
    });

    const filterModel: GridFilterModel = {
        items: filterModelItems,
    };

    const { getDataGridProps, invalidate, state } = useI2pServerDataGrid<IViewProductPrices>({
        columns: columns,
        name: QUERY_KEYS.productPrices,
        getData: async state => {
            return productPricesService.getGridData({ state, permittedRowLevels });
        },
        initialState: {
            pageSize: 10,
            sortModel: [{ field: 'material', sort: 'desc' }],
            filterModel: { ...filterModel, linkOperator: GridLinkOperator.Or },
        },
        dataGridInitialState: {
            pinnedColumns: { left: ['__check__', 'material'] },
            columns: {
                // this is to force datagrid pro to store initial columnVisibilityModel as part of
                // apiRef.current.state so it can be reverted to in the default view from the toolbar
                columnVisibilityModel: {
                    Id: false,
                    newRecordId: false,
                    massActionId: false,
                    businessLine: false,
                    peerGroup: false,
                    productHierarchy1: false,
                    adjustedPH2: false,
                    pmi: false,
                    agFoodCompositeIndex: false,
                    forecastedCost6Month: false,
                    forecastedCost9Month: false,
                    recommendedMinimumCMPercent: preferences.isShowCMPercentPreferred,
                    recommendedTargetCMPercent: preferences.isShowCMPercentPreferred,
                    globalRevisedMinimumCMPercent: preferences.isShowCMPercentPreferred,
                    globalRevisedTargetCMPercent: preferences.isShowCMPercentPreferred,
                    regionalRevisedMinimumCMPercent: preferences.isShowCMPercentPreferred,
                    regionalRevisedTargetCMPercent: preferences.isShowCMPercentPreferred,
                },
            },
        },
        rowsPerPageOptions: [10, 20, 50, 100],
    });

    const { isInProgress, isLoading } = useCustomerProductEngineBatchMetaDatas();
    useInvalidateAfterBatchUpdate({ isInProgress, invalidate });

    const { getDataGridEditProps } = useI2pDataGridEdit<IViewProductPrices>({
        onEdit: async (newViewRow, oldViewRow) => {
            const nonStandardUomData = await nonStandardUomDropdownService.get(
                getNonStandardUomDropdownParams(oldViewRow.materialId)
            );
            const materialUomList = makeMaterialUomList({
                uomList: uomRows,
                nonStandardUomList: nonStandardUomData,
            });

            const hasUomChanged = oldViewRow.uom !== newViewRow.uom;

            const newKgConversion = uomRows.find(uomRow => uomRow.uom === newViewRow.uom)?.kgConversion;
            const materialKaiKgConversionFactor = oldViewRow.materialKaiKgConversionFactor;

            if (!uomRows.length && !nonStandardUomData.length) throw Error('Missing UOM reference values');

            const fixKaiValue = (uiInputValue: number) => {
                if (newViewRow.uom !== UOM_KAI) {
                    const nonStandardConversionType = uomConversionService.getNonStandardConversionType({
                        oldUom: newViewRow.uom,
                        newUom: UOM_KAI,
                        materialUomList,
                    });

                    if (nonStandardConversionType === MaterialNonStandardUOMConversion.NonStandardToKAI) {
                        const matchingFromNonStandardUom = uomConversionService.matchingNonStandardUom({
                            uom: newViewRow.uom,
                            materialUomList,
                        });

                        return uomConversionService.convertNonStandardUomToKai({
                            nonStandardPrice: uiInputValue,
                            fromFactor: matchingFromNonStandardUom.factor,
                            kaiKgConversionFactor: materialKaiKgConversionFactor,
                        });
                    }

                    if (!newKgConversion) throw Error('Missing KAI/KG conversion factor');
                    return uomConversionService.convertUomToKai({
                        uomPrice: uiInputValue,
                        kgConversion: newKgConversion,
                        materialKaiKgConversionFactor: materialKaiKgConversionFactor,
                    });
                }

                return uiInputValue;
            };

            const fixedKaiEditableFields = {
                globalRevisedMinimum: newViewRow.globalRevisedMinimum
                    ? fixKaiValue(newViewRow.globalRevisedMinimum)
                    : newViewRow.globalRevisedMinimum,
                globalRevisedTarget: newViewRow.globalRevisedTarget
                    ? fixKaiValue(newViewRow.globalRevisedTarget)
                    : newViewRow.globalRevisedTarget,
                regionalRevisedMinimum: newViewRow.regionalRevisedMinimum
                    ? fixKaiValue(newViewRow.regionalRevisedMinimum)
                    : newViewRow.regionalRevisedMinimum,
                regionalRevisedTarget: newViewRow.regionalRevisedTarget
                    ? fixKaiValue(newViewRow.regionalRevisedTarget)
                    : newViewRow.regionalRevisedTarget,
            };

            const fixedNewViewRow = {
                ...newViewRow,
                ...fixedKaiEditableFields,
            };

            const editableOldRows = {
                globalRevisedMinimum: oldViewRow.globalRevisedMinimum,
                globalRevisedTarget: oldViewRow.globalRevisedTarget,
                regionalRevisedMinimum: oldViewRow.regionalRevisedMinimum,
                regionalRevisedTarget: oldViewRow.regionalRevisedTarget,
            };

            const editableNewRows = {
                globalRevisedMinimum: fixedNewViewRow?.globalRevisedMinimum
                    ? round(fixedNewViewRow?.globalRevisedMinimum, 10)
                    : fixedNewViewRow?.globalRevisedMinimum,
                globalRevisedTarget: fixedNewViewRow?.globalRevisedTarget
                    ? round(fixedNewViewRow?.globalRevisedTarget, 10)
                    : fixedNewViewRow?.globalRevisedTarget,
                regionalRevisedMinimum: fixedNewViewRow?.regionalRevisedMinimum
                    ? round(fixedNewViewRow?.regionalRevisedMinimum, 10)
                    : fixedNewViewRow?.regionalRevisedMinimum,
                regionalRevisedTarget: fixedNewViewRow?.regionalRevisedTarget
                    ? round(fixedNewViewRow?.regionalRevisedTarget, 10)
                    : fixedNewViewRow?.regionalRevisedTarget,
            };

            const isEdited = determineIsEdited<IViewProductPrices>(editableOldRows, editableNewRows);

            if (hasUomChanged) {
                await productPricesPersistedAttributesService.softEditPersistedAttributes({
                    newViewRow,
                    oldViewRow,
                    userDisplayName: user?.displayName,
                });

                invalidate();
            }

            if (!isEdited) return hasUomChanged ? fixedNewViewRow : oldViewRow;

            const isGlobalEditor =
                permissions.isGlobalRevisedMinimumWriteAllowed && permissions.isGlobalRevisedTargetWriteAllowed;

            const rowBusinessLineLockedStatus = isBusinessLineLocked(oldViewRow.businessLine);

            const canEditGlobal = isGlobalEditor && !rowBusinessLineLockedStatus.isLocked;

            const productPricesSoftEditResult = await productPricesService.softEditGridRowData({
                isForeignCurrency,
                newViewRow: {
                    ...fixedNewViewRow,
                    globalRevisedMinimum: canEditGlobal
                        ? fixedNewViewRow.globalRevisedMinimum
                        : oldViewRow.globalRevisedMinimum,
                    globalRevisedTarget: canEditGlobal
                        ? fixedNewViewRow.globalRevisedTarget
                        : oldViewRow.globalRevisedTarget,
                },
                oldViewRow,
                userDisplayName: user?.displayName,
                userApproverTier,
            });
            invalidate();
            enqueueSnackbar('Edit saved', { variant: 'success' });
            setErrorModalMessage('');
            return productPricesSoftEditResult.newViewRow;
        },
        onError: (error: Error) => {
            invalidate();
            enqueueSnackbar(`Edit failed: ${error.message}`, { variant: 'error' });
            setErrorModalMessage(error.message);
        },
    });
    const dataGridEditProps = getDataGridEditProps();

    const dataGridProps = getDataGridProps();

    return (
        <Box height='calc(100vh - 300px)'>
            <ProvidePricesInvalidator invalidateQuery={invalidate}>
                <DataGridPremium
                    {...dataGridProps}
                    {...dataGridEditProps}
                    sx={DATA_GRID_STYLE}
                    disableSelectionOnClick
                    components={{
                        Toolbar: ProductPricesCustomToolbar,
                        LoadingOverlay: LinearProgress,
                    }}
                    componentsProps={{
                        panel: {
                            sx: {
                                '& .MuiDataGrid-filterFormColumnInput': {
                                    width: 'auto',
                                },
                            },
                        },
                        toolbar: {
                            state,
                            invalidate,
                            rowCount: dataGridProps.rowCount,
                            regionalCurrencyLocked,
                        },
                    }}
                    disableVirtualization={false}
                    headerHeight={HEADER_HEIGHT}
                    getRowId={row => row.id}
                    experimentalFeatures={{ newEditingApi: true }}
                    editMode='row'
                    isCellEditable={params => {
                        const isBatchRunning = isInProgress(params.row.businessLine) || isLoading;
                        if (['uom'].includes(params.field)) {
                            return !isBatchRunning && !isForeignCurrency;
                        }
                        return !isBatchRunning;
                    }}
                    onRowEditStart={() => setRegionalCurrencyLocked(true)}
                    onRowEditStop={params => {
                        if (params.reason !== GridRowEditStopReasons.escapeKeyDown) {
                            params.reason = GridRowEditStopReasons.rowFocusOut;
                        }
                        setRegionalCurrencyLocked(false);
                    }}
                />
            </ProvidePricesInvalidator>
            <ProductPriceDrawer setSelectedProductRow={setSelectedProduct} selectedProduct={selectedProduct} />

            <Dialog onClose={() => setErrorModalMessage('')} open={!!errorModalMessage}>
                <DialogTitle> Edit not allowed! </DialogTitle>
                <DialogContent>
                    <Alert severity='warning'>{errorModalMessage}</Alert>
                    <DialogContentText p={2} id='alert-dialog-error'>
                        You cannot set a target price below the minimum price or a minimum price above a target price.
                        Please try again with a new value.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorModalMessage('')}> OK </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
