export const TABLE_PRODUCT_PRICES = 'ProductPrices' as const;
export const VIEW_PRODUCT_PRICES = 'vwProductPrices' as const;

export const VIEW_MARKET_PRICE_HISTORY = 'vwMarketPriceHistory' as const;

export const VIEW_EXCHANGE_RATES = 'vwFXConversion' as const;

export const TABLE_PRODUCT_PRICES_COMMENTS = 'ProductPricesComments' as const;
export const TABLE_PRODUCT_PRICES_PERSISTED_ATTRIBUTES = 'ProductPricesPersistedAttributes' as const;

export const TABLE_BATCH_META_DATA = 'BatchMetaData' as const;

export const TABLE_PRODUCT_PRICES_LOCKS = 'ProductPricesLocks' as const;
export const VIEW_PRODUCT_PRICES_LOCKS = 'vwProductPricesLocks' as const;

export const QUERY_NAME_PRODUCT_PRICES_UPDATE_NEW = 'spUpdateNewProductPrices';

export const COMMENTS_TAB = 'comments';
export const MODEL_DETAILS_TAB = 'modelDetails';
export const PRICING_ANALYTICS_TAB = 'pricingAnalytics';
export const MARKET_PRICE_HISTORY = 'marketPriceHistory';
export const CUSTOMER_PRICE_HISTORY = 'customerPriceHistory';

export const productPricesDrawerTabOptions = [
    MODEL_DETAILS_TAB,
    PRICING_ANALYTICS_TAB,
    COMMENTS_TAB,
    MARKET_PRICE_HISTORY,
];
export const customerPricesDrawerTabOptions = [
    MODEL_DETAILS_TAB,
    PRICING_ANALYTICS_TAB,
    COMMENTS_TAB,
    CUSTOMER_PRICE_HISTORY,
];

export const productPriceStatuses = {
    REGIONAL_APPROVED: 'Regional Approved',
    GLOBAL_APPROVED: 'Global Approved',
    REGIONAL_REJECTED: 'Regional Not Approved',
    GLOBAL_REJECTED: 'Global Not Approved',
    REGIONAL_APPROVAL_REQUIRED: 'Regional Approval Required',
    GLOBAL_APPROVAL_REQUIRED: 'Global Approval Required',
    NEEDS_REVIEW: 'Needs Review',
};

export const KAI_UOM_VALUE_OPTION: {
    value: any;
    label: string;
} = { value: 'KAI', label: 'KAI - Kg of Active Ingredient' };
