import { ITableBankDetails } from 'shared/types';
import { MASS_ACTION_TYPES } from './massActions';

export const TABLE_CUSTOMER_PRICES_EXCEPTIONS = 'CustomerPricesExceptions' as const;
export const TABLE_CUSTOMER_PRICES_PERSISTED_ATTRIBUTES = 'CustomerPricesPersistedAttributes' as const;
export const VIEW_MATERIAL_KAI_KG_CONVERSION = 'vwMaterialKAIKGConversion' as const;
export const TABLE_UOM_CONVERSION = 'UOMConversion' as const;
export const TABLE_CUSTOMER_PRICES_USER_PERMITTED_ROW_LEVELS = 'CustomerPricesUserPermittedRowLevels' as const;
export const TABLE_CUSTOMER_PRICES_COMMENTS = 'CustomerPricesComments' as const;
export const VIEW_CUSTOMER_PRODUCT_ENGINE_BATCH_META_DATA = 'vwCustomerProductEngineBatchMetaData' as const;
export const TABLE_QUOTE_DEFAULTS = 'QuoteDefaults' as const;
export const VIEW_STANDARD_NON_STANDARD_UOMS = 'vwDropdownNonStandardAndStandardUoms' as const;
export const CUSTOMER_PRICES_PDF_COMPANY_NAME_OPTIONS = 'vwCompanies' as const;
export const TABLE_BANK_DETAILS = 'BankDetails' as const;

export const TABLE_TEMPORAL_CUSTOMER_PRICES = 'TemporalCustomerPrices' as const;
export const VIEW_TEMPORAL_CUSTOMER_PRICES = 'vwTemporalCustomerPrices' as const;
export const VIEW_TEMPORAL_CUSTOMER_PRICES_PAGE = 'vwTemporalCustomerPricesPage' as const;
export const SP_TEMPORAL_GET_CUSTOMER_PRICE_HISTORY = 'spTemporalGetCustomerPriceHistory' as const;
export const SP_TEMPORAL_MASS_ACTION_CUSTOMER_PRICES = 'spTemporalMassActionCustomerPrices' as const;
export const SP_TEMPORAL_MASS_ACTION_CUSTOMER_PRICES_APPROVAL = 'spTemporalMassActionCustomerPricesApproval' as const;
export const SP_TEMPORAL_MASS_ACTION_CUSTOMER_PRICES_SUBMIT = 'spTemporalMassActionCustomerPricesSubmit' as const;
export const SP_TEMPORAL_MASS_ACTION_CUSTOMER_PRICES_VALIDATION = 'spTemporalMassActionCustomerPricesValidation' as const;
export const SP_TEMPORAL_GET_CUSTOMER_PRICE_EXCEPTIONS = 'spTemporalGetCustomerPricesExceptions' as const;

export const customerPriceStatuses = {
    APPROVED: 'Approved',
    REJECTED: 'Not Approved',
    APPROVAL_REQUIRED: 'Approval Required',
    NEEDS_REVIEW: 'Needs Review',
    NO_CHANGE: 'No Change',
};

export const CUSTOMER_PRICES_PDF_COMPANY_OPTIONS_DEMO = [
    'Insight2Profit Cleveland',
    'Insight2Profit Chicago',
    'Insight2Profit',
];

export const CUSTOMER_PRICES_PDF_ADDRESS_OPTIONS_DEMO = [
    `Insight2Profit
125 S Wacker Dr SUITE 1300, 
Chicago, IL 60606
United States`,
    `Insight2Profit
3333 Richmond Rd SUITE200,
Beachwood, OH 44122
United States`,
];

export const CUSTOMER_PRICES_PDF_BANK_SELECTION_OPTIONS_DEMO: ITableBankDetails[] = [
    {
        bankDetailDefaultId: 1,
        payment: 'Payment in USD',
        bankName: 'United States Bank',
        accountHolder: 'Account Holder: Insight2Profit Chemicals US Co. Ltd.',
        iban: 'IBAN: 000121200',
        swift: 'SWIFT: USBI2P',
        createdBy: 'Import Data Load',
        updatedBy: 'Import Data Load',
        updatedReason: 'Import Data Load',
        isDeleted: false,
    },
    {
        bankDetailDefaultId: 2,
        payment: 'Payment in EUR',
        bankName: 'Bank of Europe',
        accountHolder: 'Account Holder: Insight2Profit Chemicals Europe Co. Ltd.',
        iban: 'IBAN: 123456789',
        swift: 'SWIFT: ABCDEFG',
        createdBy: 'Import Data Load',
        updatedBy: 'Import Data Load',
        updatedReason: 'Import Data Load',
        isDeleted: false,
    },
];

export const PAYMENT_TERMS = [
    'CAD - Cash against documents (D/P)',
    'I005 - Within 5 days after date of invoice',
    'I010 - Within 10 days after date of invoice',
    'I015 - Within 15 days after date of invoice',
    'I020 - Within 20 days after date of invoice',
    'I025 - Within 25 days after date of invoice',
    'I030 - Within 30 days after date of invoice',
    'I040 - Within 40 days after date of invoice',
    'I045 - Within 45 days after date of invoice',
    'I055 - Within 55 days after date of invoice',
    'I060 - Within 60 days after date of invoice',
    'I075 - Within 75 days after date of invoice',
    'I090 - Within 90 days after date of invoice',
    'L000 - L/C payable at sight',
    'L030 - L/C payable 30 days after date of shipment',
    'L045 - L/C payable 45 days after date of shipment',
    'L060 - L/C payable 60 days after date of shipment',
    'M030 - Within 30 days after end of month of invoice',
    'M040 - Within 40 days after end of month of invoice',
    'M060 - Within 60 days after end of month of invoice',
    'X001 - Payment in advance',
    'X000 - Without payment',
    'BL30 - 30 days after Bill of Lading',
    'BL45 - 45 days after Bill of Lading',
    'BL60 - 60 days after Bill of Lading',
    'BL90 - 90 days after Bill of Lading',
    'D030 - Against Draft 30 days after date of shipment',
    'D045 - Against Draft 45 days after date of shipment',
    'D060 - Against Draft 60 days after date of shipment',
    'J030 - RIBA 30 days after end of month of invoice',
    'J040 - RIBA 45 days after end of month of invoice',
];

export const USER_ADDED_SAP_DATASOURCE = 'User added SAP customer';
export const USER_ADDED_PROSPECTIVE_DATA_SOURCE = 'User added prospective customer';

export const EXCEPTION_STATUS_OPTIONS = [
    'All Exceptions Approved',
    'Exception Approval Required',
    'No Exceptions',
    'Submission Pending',
];
export const REGIONAL_CURRENCY_NOT_EDITABLE = ['documentCurrency', 'uom'];

export const TYPE_OPTIONS = [
    MASS_ACTION_TYPES.AMOUNT,
    MASS_ACTION_TYPES.CHANGE_DOLLAR,
    MASS_ACTION_TYPES.CHANGE_PERCENT,
    MASS_ACTION_TYPES.APPROVE,
    MASS_ACTION_TYPES.REJECT,
    MASS_ACTION_TYPES.SUBMIT,
    MASS_ACTION_TYPES.VALIDITY_DATES,
    MASS_ACTION_TYPES.UOM,
] as string[];

export const APPROVAL_CHANGE_MASS_ACTIONS = [MASS_ACTION_TYPES.APPROVE, MASS_ACTION_TYPES.REJECT] as string[];
