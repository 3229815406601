export const QUERY_KEYS = {
    productPrices: 'ProductPrices',
    productPriceComments: 'ProductPriceComments',
    productPricesLocks: 'ProductPricesLocks',
    productPricesLocksEdit: 'ProductPricesLocksEdit',
    temporalCustomerPricesPage: 'TemporalCustomerPricesPage',
    customerPricesExceptions: 'CustomerPricesExceptions',
    customerPricesQuote: 'CustomerPricesQuote',
    customerPriceComments: 'CustomerPriceComments',
    exchangeRates: 'ExchangeRates',
    customerProductEngineBatchMetaDatas: 'CustomerProductEngineBatchMetaData',
    customerProductEngineBatchMutation: 'CustomerProductEngineBatchMutation',
    customerPricesSoldToDropdown: 'customerPricesSoldToDropdownQuery',
    customerPricesShipToDropdown: 'customerPricesShipToDropdownQuery',
    customerPricesMaterialDropdown: 'customerPricesMaterialDropdown',
    customerPriceOrgRegionDropdown: 'customerPriceOrgRegionDropdownQuery',
    customerPriceApplicationDropdown: 'customerPriceApplicationDropdownQuery',
    customerPricesMarketSegmentsDropdown: 'customerPricesMarketSegmentsDropdownQuery',
    customerPricesSalesOrganizationsAddProspectiveDropdown:
        'customerPricesSalesOrganizationsAddProspectiveDropdownQuery',
    customerPricesSalesOrganizationsAddExistingDropdown: 'customerPricesSalesOrganizationsAddExistingDropdownQuery',
    customerPricesAccountManagersDropdown: 'customerPricesAccountManagersDropdownQuery',
    productPricesMaterialDropdown: 'productPricesMaterialDropdownQuery',
    productPricesApplicationDropdown: 'productPricesApplicationDropdownQuery',
    productPricesMarketSegmentDropdown: 'productPricesMarketSegmentDropdownQuery',
    productPricesOrgRegionDropdown: 'productPricesOrgRegionDropdownQuery',
    productPricesMarketPriceHistory: 'productPricesMarketPriceHistory',
    uomDropdown: 'uomDropdown',
    nonStandardAndStandardUomDropdown: 'nonStandardAndStandardUomDropdown',
    nonStandardUOMDropdown: 'nonStandardUOMDropdown',
    getTempooralCustomerPriceHistory: 'getTemporalCustomerPriceHistory',
} as const;
